const styles = {
  content: {
    position: "absolute",
    top: "50%",
    right: "5%",
    transform: "translateY(-50%)"
  },
  contentFullWidth: {
    position: "absolute",
    top: "50%",
    right: "5%",
    transform: "translateY(-50%)",
    width: "95%"
  },
  logout: {
    display: "inline-block",
    verticalAlign: "middle",
    minWidth: 50
  }
};
export default styles;
