import { Checkbox, FormControlLabel } from "@material-ui/core";

import React from "react";
import { withStyles } from "@material-ui/styles";

const styles = {
  root: {
    alignItems: "flex-start"
  },
  label: {
    fontSize: 16
  },
  checkbox: {
    height: "auto"
  }
};

/**
 * Composed from `FormControlLabel` with a `Checkbox` from MaterialUI, props
 * are passed down to the `FormControlLabel`.
 *
 * @see https://v1.material-ui.com/api/form-control-label/
 */
export const FormCheckLineComponent = ({ classes, style, ...props }) => {
  const { checkbox, ...formControlClasses } = classes;

  return (
    <div id="checkboxLabel" style={style}>
      <FormControlLabel
        classes={formControlClasses}
        control={<Checkbox id="checkboxInput" classes={{ root: checkbox }} />}
        {...props}
      />
    </div>
  );
};

export const FormCheckLine = withStyles(styles)(FormCheckLineComponent);
FormCheckLine.propTypes = FormControlLabel.propTypes;
