const styles = {
  sliderHolder: {
    position: "relative"
  },
  sliderMarks: {
    position: "absolute",
    right: 0,
    bottom: 0,
    display: "block",
    minWidth: 60,
    fontSize: 14,
    fontWeight: "bold"
  },
  sliderStyles: {
    marginTop: "0px",
    marginBottom: "24px"
  }
};

export default styles;
