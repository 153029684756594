import React, { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import _ from "lodash";

import { abbreviatedMonths, days, months, years } from "../../data/dates";
import { FormDropdown } from "../FormDropdown/FormDropdown";

import { DateSelectStyles as styles } from "../../styles";

export const DateInput = ({ value, onChange, looksDisabled }) => {
  const [_value, _setValue] = useState({});

  /** Return a callback that processes a specific field's onChange */
  const onFieldChanged = field => ({ target }) => {
    // Avoid unnecessary re-calculations and re-renders
    if (_value[field] === target.value) {
      return;
    }

    // Update the field's value
    const newValue = {
      ..._value,
      [field]: parseInt(target.value)
    };
    _setValue(newValue);
  };

  /** Controlled and internal value in sync */
  useEffect(() => {
    if (!_.isEqual(_value, value)) {
      const newValue = _.mapValues(value, parseInt);
      _setValue(newValue);
    }
  }, [value]);
  useEffect(() => {
    onChange && onChange(_value);
  }, [_value]);

  // Patch to prevent `undefined` exceptions
  value = value || {};

  return (
    <div style={styles.container}>
      <FormDropdown
        labelId="dobMonth"
        style={{ ...styles.select, width: "calc(33% - 12px)" }}
        value={_value.month}
        renderValue={() => abbreviatedMonths[value.month]}
        onChange={onFieldChanged("month")}
        looksDisabled={looksDisabled}
      >
        {Object.keys(months).map(key => (
          <MenuItem
            className="menuItemDropdown"
            key={`date_month_${key}`}
            value={key}
          >
            {months[key]}
          </MenuItem>
        ))}
      </FormDropdown>
      <span style={looksDisabled ? styles.disabledSlash : styles.slash}>/</span>
      <FormDropdown
        labelId="dobDay"
        style={{ ...styles.select, width: "calc(29% - 12px)" }}
        value={_value.day}
        onChange={onFieldChanged("day")}
        looksDisabled={looksDisabled}
      >
        {days.map(day => (
          <MenuItem
            className="menuItemDropdown"
            key={`date_day_${day}`}
            value={day}
          >
            {day}
          </MenuItem>
        ))}
      </FormDropdown>
      <span style={looksDisabled ? styles.disabledSlash : styles.slash}>/</span>
      <FormDropdown
        labelId="dobYear"
        style={{ ...styles.select, width: "calc(38% - 12px)" }}
        value={_value.year}
        onChange={onFieldChanged("year")}
        looksDisabled={looksDisabled}
      >
        {years.map(year => (
          <MenuItem
            className="menuItemDropdown"
            key={`date_year_${year}`}
            value={year}
          >
            {year}
          </MenuItem>
        ))}
      </FormDropdown>
    </div>
  );
};
DateInput.propTypes = {
  value: PropTypes.shape({
    year: PropTypes.number,
    month: PropTypes.number,
    day: PropTypes.number
  }),
  onChange: PropTypes.func,
  looksDisabled: PropTypes.bool
};
