/* eslint-disable no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { turnAPI, ga } from "../../services";
import Error from "../ErrorPage/new";

import CandidateReport from "../Candidate/Report";
import CandidateReportModel from "../../models/candidate";
import StatusErrors from "./status-errors";
import ReportAuthorizationPage from "../ReportAuthorizationPage";
import { PageType, PageEvent } from "../../services/analytics/gtm";
import env from "@mars/heroku-js-runtime-env";

const BackgroundReport = observer(
  class BackgroundReport extends Component {
    static propTypes = { params: PropTypes.object };
    static contextTypes = { router: PropTypes.object };
    constructor(props) {
      super(props);
      this.state = { errorMessage: null, report_token: null };
      this.model = new CandidateReportModel();
    }
    UNSAFE_componentWillMount() {
      this.model.candidateConsentEmailId = this.props.params.email_id;
      const report_token = new URLSearchParams(window.location.search).get(
        "report_token"
      );
      this.setState({ report_token });
      this.getBackgroundReport(report_token);
    }
    getBackgroundReport = async _token => {
      const token =
        _token ||
        window.sessionStorage.getItem("report_token") ||
        this.state.report_token;
      const response = await turnAPI.getBackgroundReport(
        this.props.params.email_id,
        token
      );
      switch (response.status) {
        case 200:
          this.handleResponse(response.data);
          break;
        case 401:
          this.model.handleUnauthorized(response.data);
          break;
        case 451:
          this.handleDDPPARStatus();
          break;
        default:
          this.handleStatusErrors(response.status);
          break;
      }
    };

    handleResponse = data => {
      if (typeof data.background_report === "string")
        this.handleStatusErrors(410);
      this.model.setIsPendingAgeReview(data.age_review);
      this.model.setSsnReview(data.ssn_review);
      this.model.setIsPendingMVRReview(data.mvr_review);
      this.model.setPartner(data.partner_name);
      this.model.setAddressForFcra(data.address_for_fcra);
      this.model.setUser(data.user_id);
      this.model.setData(data);
      this.model.setUnauthorized(false);
    };

    handleDDPPARStatus = () => {
      const currentUrl = window.location.href;

      const match = currentUrl.match(/\/consent\/([^/]+)\/report/);
      const candidate_id = match && match[1];

      if (candidate_id) {
        window.location.href = `${
          env().REACT_APP_CONSENT_URL
        }/consent/${candidate_id}/redacted`;
      }
    };

    handleStatusErrors = status =>
      this.setState({ errorMessage: <StatusErrors status={status} /> });

    submitAssent = async () => {
      // NOTE: this is not actually being used in the BGC flow
      // GOTO: app/containers/BGCLandingPage/index.js
      this.model.setLoading(true);
      const response = await turnAPI.assent(
        this.props.params.email_id,
        this.model.assentData
      );
      switch (response.status) {
        case 200:
          this.model.setConsent(true);
          this.model.setIsPendingMVRReview(this.model.isPendingMVRReview);
          this.gotoSuccessPage();
          break;
        case 203:
          this.model.setConsent(true);
          this.model.setIsPendingMVRReview(true);
          this.gotoSuccessMVRPage();
          break;
        default:
          this.handleStatusErrors(response.status);
          break;
      }
      this.model.setLoading(false);
    };

    gotoSuccessPage() {
      this.context.router.history.push("/candidate/success");
    }

    gotoSuccessMVRPage() {
      this.context.router.history.push("/candidate/successMVR");
    }

    componentDidMount() {
      ga.send(PageType.BackgroundReportPage, PageEvent.pageLoaded);
    }

    render() {
      if (this.state.errorMessage)
        return <Error message={this.state.errorMessage} />;
      if (this.model.unauthorized)
        return (
          <ReportAuthorizationPage
            handleSubmit={this.getBackgroundReport}
            model={this.model}
          />
        );
      return (
        <CandidateReport model={this.model} onSubmit={this.submitAssent} />
      );
    }
  }
);

export default BackgroundReport;
