import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { Component } from "react";
import { camelCase, isEmpty, snakeCase, upperFirst } from "lodash";

import DateSelect from "../DateSelect";
import FieldSet from "../Form/field-set";
import { FormDropdown } from "../FormDropdown/FormDropdown";
import Link from "../Link";
import MenuItem from "@material-ui/core/MenuItem";
import { PropTypes } from "prop-types";
import RadioSelect from "../RadioSelect";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import messages from "./messages";
import { observer } from "mobx-react";
import states from "../../data/us-states";
import countries from "../../data/countries";
import { LandingPageStyles as styles } from "../../styles";
import syslog from "../../services/syslog";
import { GENDERS, SSN_TRACE_MULTIPLE_ERROR } from "../../constants";
import FormLockContainer from "./components/FormLockContainer";

export const Error = ({ error }) =>
  error ? <span style={styles.error}>{error}</span> : null;
export const Warning = ({ warning }) =>
  warning ? <span style={styles.warning}>{warning}</span> : null;
Error.propTypes = { error: PropTypes.string };
Warning.propTypes = { warning: PropTypes.string };

const saveTemporaryFieldValue = (fieldName, fieldValue) => {
  localStorage.setItem(fieldName, fieldValue);
};

export const getFieldsetProps = (model, fieldName) => {
  const shouldRender =
    fieldName !== "phone"
      ? model.requiredFields.includes(snakeCase(fieldName))
      : model.requiredFields.includes("phone_number");
  const disabled = model.formLock;
  return {
    htmlFor: fieldName,
    message: messages[`${fieldName}Label`],
    shouldRender,
    disabled
  };
};

export const getFieldProps = (model, fieldName) => {
  const value = model[fieldName];
  const onBlur = model[camelCase(`touch_${fieldName}`)];
  const error = model[camelCase(`invalid_${fieldName}`)];
  if (!!error && !!model[`unfreeze${upperFirst(fieldName)}`])
    model[`unfreeze${upperFirst(fieldName)}`]();
  const disabled = model[`freezed${upperFirst(fieldName)}`] || model.formLock;
  const onChange = e => {
    // setValue
    model[camelCase(`set_${fieldName}`)](e.target.value);
    // validate
    model[camelCase(`validate_${fieldName}`)]();
    // logValue
    const logsFields = [
      FirstNameField.defaultProps.name,
      MiddleNameField.defaultProps.name,
      LastNameField.defaultProps.name
    ];
    if (logsFields.indexOf(fieldName) != -1) {
      syslog([{ inputName: fieldName, [fieldName + "Value"]: e.target.value }]);
    }
  };
  return {
    id: fieldName,
    value,
    onChange,
    onBlur,
    error,
    autocomplete: "nope",
    autoFocus: model.autofocus === fieldName,
    disabled
  };
};

export const getErrorProps = (model, fieldName) => ({
  error: model.errorState[fieldName]
});

export const getWarningProps = (model, fieldName) => ({
  warning: model.warningState[fieldName]
});

export const getProps = (model, fieldName) => ({
  fieldSet: getFieldsetProps(model, fieldName),
  field: getFieldProps(model, fieldName),
  error: getErrorProps(model, fieldName),
  warning: getWarningProps(model, fieldName)
});

const fieldPropTypes = {
  model: PropTypes.object.isRequired,
  name: PropTypes.string
};

export const BirthCountryField = observer(({ model, name, width }) => {
  const { birthCountry, touchBirthCountry } = model;
  return (
    <FieldSet {...getFieldsetProps(model, name)} width={width}>
      <Select
        fullWidth
        id={name}
        style={styles.select}
        value={birthCountry}
        onChange={e => {
          model.setBirthCountry(e.target.value);
        }}
        onBlur={touchBirthCountry}
      >
        {Object.entries(countries).map((country, i) => (
          <MenuItem className="menuItemDropdown" key={i} value={country[0]}>
            {country[1]}
          </MenuItem>
        ))}
      </Select>
    </FieldSet>
  );
});
BirthCountryField.propTypes = fieldPropTypes;
BirthCountryField.defaultProps = { name: "birthCountry" };

export const AddressCountryField = observer(({ model, name, width }) => {
  const props = getProps(model, name);
  const countryDisabled = true;
  return (
    <FieldSet {...props.fieldSet} width={width}>
      <TextField
        disabled={countryDisabled}
        fullWidth
        autocomplete="nope"
        style={styles.input}
        {...props.field}
      />
      <Error {...props.error} />
      <Warning {...props.warning} />
    </FieldSet>
  );
});
AddressCountryField.propTypes = fieldPropTypes;
AddressCountryField.defaultProps = { name: "addressCountry" };

export const AddressProvinceField = observer(({ model, name, width }) => {
  const props = getProps(model, name);
  return (
    <FieldSet {...props.fieldSet} width={width}>
      <TextField
        fullWidth
        autocomplete="nope"
        style={styles.input}
        {...props.field}
      />
      <Error {...props.error} />
      <Warning {...props.warning} />
    </FieldSet>
  );
});
AddressProvinceField.propTypes = fieldPropTypes;
AddressProvinceField.defaultProps = { name: "addressProvince" };

export const AddressCityField = observer(({ model, name, width }) => {
  const props = getProps(model, name);
  return (
    <FieldSet {...props.fieldSet} width={width}>
      <TextField
        fullWidth
        autocomplete="nope"
        style={styles.input}
        {...props.field}
      />
      <Error {...props.error} />
      <Warning {...props.warning} />
    </FieldSet>
  );
});
AddressCityField.propTypes = fieldPropTypes;
AddressCityField.defaultProps = { name: "addressCity" };

export const AddressStreetField = observer(({ model, name, width }) => {
  const props = getProps(model, name);
  return (
    <FieldSet {...props.fieldSet} width={width}>
      <TextField
        fullWidth
        autocomplete="nope"
        style={styles.input}
        {...props.field}
      />
      <Error {...props.error} />
      <Warning {...props.warning} />
    </FieldSet>
  );
});
AddressStreetField.propTypes = fieldPropTypes;
AddressStreetField.defaultProps = { name: "addressStreet" };

export const AddressZipcodeField = observer(({ model, name, width }) => {
  const props = getProps(model, name);
  return (
    <FieldSet {...props.fieldSet} width={width}>
      <TextField
        fullWidth
        autocomplete="nope"
        style={styles.input}
        {...props.field}
      />
      <Error {...props.error} />
      <Warning {...props.warning} />
    </FieldSet>
  );
});
AddressZipcodeField.propTypes = fieldPropTypes;
AddressZipcodeField.defaultProps = { name: "addressZipcode" };

export const FirstNameField = observer(({ model, name, width }) => {
  const props = getProps(model, name);
  return (
    <FieldSet {...props.fieldSet} width={width}>
      <TextField
        fullWidth
        autocomplete="nope"
        style={styles.input}
        {...props.field}
      />
      <Error {...props.error} />
      <Warning {...props.warning} />
    </FieldSet>
  );
});
FirstNameField.propTypes = fieldPropTypes;
FirstNameField.defaultProps = { name: "firstName" };

export const LastNameField = observer(({ model, name, width }) => {
  const props = getProps(model, name);
  return (
    <FieldSet {...props.fieldSet} width={width}>
      <TextField
        fullWidth
        autocomplete="nope"
        style={styles.input}
        {...props.field}
      />
      <Error {...props.error} />
    </FieldSet>
  );
});
LastNameField.propTypes = fieldPropTypes;
LastNameField.defaultProps = { name: "lastName" };

export const MiddleNameField = observer(({ model, name, width }) => {
  const fieldName = "middleName";
  const props = getProps(model, fieldName);
  return (
    <FieldSet
      htmlFor={name}
      disabled={model.hasMiddleName}
      {...props.fieldSet}
      width={width}
    >
      <TextField
        autocomplete="nope"
        fullWidth
        style={styles.input}
        {...props.field}
        required={false}
        disabled={model.hasMiddleName || model.formLock}
        onKeyUpCapture={() => {
          model.validateMiddleName();
        }}
        error={false}
      />
      <Error {...props.error} />
      <FormLockContainer formLock={model.formLock}>
        <FormControlLabel
          label="I don&#39;t have a Middle Name"
          control={
            <Checkbox
              id="hasMiddleName"
              checked={model.hasMiddleName}
              onClick={() => {
                model.setHasMiddleName(!model.hasMiddleName);
                model.setMiddleName("");
                model.validateMiddleName();
              }}
            />
          }
        />
      </FormLockContainer>
    </FieldSet>
  );
});
MiddleNameField.propTypes = fieldPropTypes;
MiddleNameField.defaultProps = { name: "middleName" };

export const HasMiddleNameField = observer(({ model, name, width }) => {
  const { hasMiddleName, requiredFields } = model;
  const show = requiredFields.includes(snakeCase(name));
  return (
    <FieldSet
      padding="0 12px 15px 0"
      htmlFor={name}
      shouldRender={show}
      width={width}
    >
      <Checkbox
        label="I don&#39;t have a Middle Name"
        checked={!hasMiddleName}
        onClick={() => {
          model.setHasMiddleName(!hasMiddleName);
          model.setMiddleName("");
          model.validateMiddleName();
        }}
        iconStyle={styles.checkbox}
      />
    </FieldSet>
  );
});
HasMiddleNameField.propTypes = fieldPropTypes;
HasMiddleNameField.defaultProps = { name: "hasMiddleName" };

export const DateOfBirthField = observer(({ model, name, width }) => {
  const show = model.requiredFields.includes("date_of_birth");
  return (
    <FieldSet
      htmlFor={name}
      message={messages.dateOfBirthLabel}
      shouldRender={show}
      width={width}
      disabled={model.formLock}
    >
      {/* This is a hack. Need to implement a proper disable */}
      <FormLockContainer formLock={model.formLock}>
        <DateSelect id={name} model={model} looksDisabled={model.formLock} />
      </FormLockContainer>
    </FieldSet>
  );
});
DateOfBirthField.propTypes = fieldPropTypes;
DateOfBirthField.defaultProps = { name: "date" };

export const GenderField = observer(({ model, name, width }) => {
  const menu_items = Object.keys(GENDERS).map(gender => (
    <MenuItem className="menuItemDropdown" value={GENDERS[gender]} key={gender}>
      {gender}
    </MenuItem>
  ));
  const hint_text = Object.keys(GENDERS).join(", ");
  return (
    <FieldSet {...getFieldsetProps(model, name)} htmlFor={name} width={width}>
      <FormLockContainer id="gender" formLock={model.formLock}>
        <FormDropdown
          fullWidth
          id={name}
          hintText={hint_text}
          style={styles.select}
          value={model.gender}
          onChange={e => {
            model.setGender(e.target.value);
          }}
          looksDisabled={model.formLock}
        >
          {menu_items}
        </FormDropdown>
      </FormLockContainer>
    </FieldSet>
  );
});
GenderField.propTypes = fieldPropTypes;
GenderField.defaultProps = { name: "gender" };

export const SSNField = observer(
  ({ model, name, width, shouldRender = false }) => {
    const props = getProps(model, name);
    return (
      <FieldSet {...props.fieldSet} shouldRender={shouldRender} width={width}>
        <TextField
          fullWidth
          autocomplete="nope"
          type="tel"
          style={styles.input}
          {...props.field}
        />
        <Error {...props.error} />
        {model.formLock && (
          <Error error="Unable to match the submitted SSN with information from public records" />
        )}
      </FieldSet>
    );
  }
);
SSNField.propTypes = fieldPropTypes;
SSNField.defaultProps = { name: "ssn" };

export const ZipcodeField = observer(({ model, name, width, shouldRender }) => {
  const props = getProps(model, name);
  return (
    <FieldSet
      {...props.fieldSet}
      width={width}
      shouldRender={shouldRender || props.fieldSet.shouldRender}
    >
      <TextField
        fullWidth
        autocomplete="nope"
        type="tel"
        style={styles.input}
        {...props.field}
      />
      <Error {...props.error} />
    </FieldSet>
  );
});
ZipcodeField.propTypes = fieldPropTypes;
ZipcodeField.defaultProps = { name: "zipcode" };

export const EmailField = observer(({ model, disabled, name, width }) => {
  const props = getProps(model, name);
  return (
    <FieldSet {...props.fieldSet} width={width}>
      <TextField
        autocomplete="nope"
        fullWidth
        type="email"
        style={styles.input}
        disabled={disabled}
        {...props.field}
      />
      <Error {...props.error} />
    </FieldSet>
  );
});
EmailField.propTypes = Object.assign(
  { disableEmailInput: PropTypes.bool },
  fieldPropTypes
);
EmailField.defaultProps = { name: "email" };

export const PhoneField = observer(({ model, name, width }) => {
  const props = getProps(model, name);
  return (
    <FieldSet {...props.fieldSet} width={width}>
      <TextField
        fullWidth
        autocomplete="nope"
        type="tel"
        style={styles.input}
        {...props.field}
      />
      <Error {...props.error} />
    </FieldSet>
  );
});
PhoneField.propTypes = fieldPropTypes;
PhoneField.defaultProps = { name: "phone" };

export const LicenseStateField = observer(({ model, name, width }) => {
  const { driversLicenseState, touchDriversLicenseState } = model;
  return (
    <FieldSet {...getFieldsetProps(model, name)} width={width}>
      <FormLockContainer formLock={model.formLock}>
        <Select
          fullWidth
          id={name}
          style={styles.select}
          value={driversLicenseState}
          onChange={e => {
            model.setDriversLicenseState(e.target.value);
            model.validateLicense();
          }}
          onBlur={touchDriversLicenseState}
          disabled={model.formLock}
        >
          {states.map(({ abbreviation, name }, i) => (
            <MenuItem className="menuItemDropdown" key={i} value={abbreviation}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormLockContainer>
    </FieldSet>
  );
});
LicenseStateField.propTypes = fieldPropTypes;
LicenseStateField.defaultProps = { name: "driversLicenseState" };

export const LicenseNumberField = observer(
  class LicenseNumberField extends Component {
    constructor(props) {
      super(props);
      this.state = { focused: false };
    }
    render() {
      const { model, name, width } = this.props;
      const {
        driversLicenseState,
        driversLicenseNumber,
        invalidLicenseNumber,
        touchDriversLicenseNumber,
        errorState
      } = model;
      return (
        <FieldSet {...getFieldsetProps(model, name)} width={width}>
          <TextField
            id={name}
            fullWidth
            autocomplete="nope"
            style={styles.input}
            value={driversLicenseNumber}
            onChange={e => {
              model.setDriversLicenseNumber(e.target.value);
              model.validateLicense();
            }}
            onBlur={touchDriversLicenseNumber}
            error={invalidLicenseNumber}
            disabled={!driversLicenseState || model.formLock}
          />
          <Error error={errorState[name]} />
        </FieldSet>
      );
    }
  }
);
LicenseNumberField.propTypes = fieldPropTypes;
LicenseNumberField.defaultProps = { name: "driversLicenseNumber" };

export const CarInsuranceField = observer(({ model, name, width }) => {
  const show = model.requiredFields.includes("has_car_insurance");
  const carInsuranceLink = process.env.REACT_APP_CAR_INSURANCE_LINK;
  return (
    <FieldSet
      width={width || "100%"}
      padding="0"
      htmlFor={name}
      shouldRender={show}
      disabled={model.formLock}
    >
      <p style={{ ...styles.checkboxP, margin: 0 }}>
        {`Do you possess automobile insurance per ${model.partnerName}'s `}
        <Link link="requirements" href={carInsuranceLink} />
        {"?"}
      </p>
      <RadioSelect
        setHasCarInsurance={model.setHasCarInsurance}
        hasCarInsurance={model.hasCarInsurance}
      />
    </FieldSet>
  );
});
CarInsuranceField.propTypes = fieldPropTypes;
CarInsuranceField.defaultProps = { name: "hasCarInsurance" };

export const getCustomFieldProps = (model, key, fieldName) => {
  const oneOffCheck = model[key];
  if (!oneOffCheck) return null;
  const customFieldModel = oneOffCheck[fieldName];
  if (!customFieldModel) return null;
  const value = customFieldModel.value || localStorage.getItem(fieldName);
  const onBlur = customFieldModel.touch;
  const error = customFieldModel.invalid;
  if (value) {
    customFieldModel.set(value);
  }
  const onChange = e => {
    customFieldModel.set(e.target.value);
    customFieldModel.validate();
    saveTemporaryFieldValue(fieldName, e.target.value);
  };
  return {
    id: fieldName,
    value,
    onChange,
    onBlur,
    error,
    autocomplete: "nope"
  };
};

export const getCustomFieldSetProps = (model, key, fieldName) => {
  const oneOffCheck = model[key];
  const customFieldModel = oneOffCheck[fieldName];

  return {
    htmlFor: fieldName,
    message: {
      id: `app.components.LandingPage.${fieldName}`,
      defaultMessage: customFieldModel.label
    },
    shouldRender: true
  };
};

export const getCustomProps = (model, key, fieldName) => ({
  field: getCustomFieldProps(model, key, fieldName),
  fieldSet: getCustomFieldSetProps(model, key, fieldName),
  error: getErrorProps(model, fieldName)
});

export const CustomFields = observer(({ model, width }) => {
  if (isEmpty(model.oneOffChecks)) return null;
  return model.oneOffChecks.map(({ name, custom_fields }) => {
    const key = camelCase(name);
    if (isEmpty(custom_fields)) return null;
    return custom_fields.map(({ input_name }, i) => {
      const fieldName = camelCase(input_name);
      const props = getCustomProps(model, key, fieldName);
      return (
        <FieldSet
          {...props.fieldSet}
          width={width}
          key={`custom_input_${name}_${i}`}
          disabled={model.formLock}
        >
          <TextField
            fullWidth
            autocomplete="nope"
            style={styles.input}
            disabled={model.formLock}
            {...props.field}
          />
          <Error {...props.error} />
        </FieldSet>
      );
    });
  });
});
CarInsuranceField.propTypes = fieldPropTypes;

//PA Release Form Elements
export const FirstNameReleaseField = observer(({ model, name, width }) => {
  const props = getProps(model, name);

  return (
    <FieldSet {...props.fieldSet} width={width || "33%"} shouldRender={true}>
      <TextField
        fullWidth
        autocomplete="nope"
        style={styles.input}
        {...props.field}
      />
      <Error {...props.error} />
    </FieldSet>
  );
});
FirstNameReleaseField.propTypes = fieldPropTypes;
FirstNameReleaseField.defaultProps = { name: "firstNameRelease" };

export const LastNameReleaseField = observer(({ model, name, width }) => {
  const props = getProps(model, name);

  return (
    <FieldSet {...props.fieldSet} width={width || "33%"} shouldRender={true}>
      <TextField
        fullWidth
        autocomplete="nope"
        style={styles.input}
        {...props.field}
      />
      <Error {...props.error} />
    </FieldSet>
  );
});
LastNameReleaseField.propTypes = fieldPropTypes;
LastNameReleaseField.defaultProps = { name: "lastNameRelease" };

export const AddressField = observer(({ model, name, width }) => {
  const props = getProps(model, name);

  return (
    <FieldSet {...props.fieldSet} width={width} shouldRender={true}>
      <TextField
        fullWidth
        autocomplete="nope"
        style={styles.input}
        {...props.field}
      />
      <Error {...props.error} />
    </FieldSet>
  );
});
AddressField.propTypes = fieldPropTypes;
AddressField.defaultProps = { name: "address" };

export const CityField = observer(({ model, name, width }) => {
  const props = getProps(model, name);

  return (
    <FieldSet {...props.fieldSet} width={width} shouldRender={true}>
      <TextField
        fullWidth
        autocomplete="nope"
        style={styles.input}
        {...props.field}
      />
      <Error {...props.error} />
    </FieldSet>
  );
});
CityField.propTypes = fieldPropTypes;
CityField.defaultProps = { name: "city" };

export const ZipcodeReleaseField = observer(({ model, name, width }) => {
  const props = getProps(model, name);
  return (
    <FieldSet {...props.fieldSet} width={width || "33%"} shouldRender={true}>
      <TextField
        fullWidth
        autocomplete="nope"
        type="tel"
        style={styles.input}
        {...props.field}
      />
      <Error {...props.error} />
    </FieldSet>
  );
});
ZipcodeReleaseField.propTypes = fieldPropTypes;
ZipcodeReleaseField.defaultProps = { name: "zipcodeRelease" };

export const InformationForm = observer(({ model: d, isCanadaCheck }) => {
  if (!d.requiredFields) {
    return null;
  }
  const showSsn = d.requiredFields.includes("ssn");
  return (
    <>
      {d.error && <p style={{ ...styles.error }}>{d.error}</p>}
      {!d.error && d.formLock && (
        <p style={{ ...styles.error }}>{SSN_TRACE_MULTIPLE_ERROR}</p>
      )}
      <FirstNameField model={d} disabled={true} />
      <LastNameField model={d} />
      <MiddleNameField model={d} />
      <DateOfBirthField model={d} />
      <GenderField model={d} />
      <SSNField model={d} shouldRender={showSsn} />
      <ZipcodeField model={d} />
      <EmailField model={d} />
      <PhoneField model={d} />
      <LicenseStateField model={d} />
      <LicenseNumberField model={d} />
      <CustomFields model={d} />
      {isCanadaCheck && (
        <>
          <BirthCountryField model={d} />
          <br />
          <span style={styles.addressMessage}>
            {messages.canadianAddress.defaultMessage}
          </span>
          <br />
          <AddressCountryField model={d} />
          <AddressProvinceField model={d} />
          <AddressCityField model={d} />
          <AddressStreetField model={d} />
          <AddressZipcodeField model={d} />
        </>
      )}
    </>
  );
});
InformationForm.propTypes = {
  model: PropTypes.object.isRequired
};
