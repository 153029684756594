const styles = {
  normal: {},
  expired: {
    backgroundColor: "red",
    borderRadius: 5,
    fontSize: 12,
    padding: "2px 10px",
    color: "white"
  }
};
export default styles;
