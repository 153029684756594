const styles = {
  button: {
    width: "auto",
    padding: 0
  },
  arrow: {
    position: "absolute",
    top: -6,
    right: -12,
    border: "1px solid transparent"
  }
};
export default styles;
